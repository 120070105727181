import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";

// Helper function to convert ArrayBuffer to base64
const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

const MediaItem = (({ media, index }) => {
  return (
    <div key={index}>
      {media.contentType.startsWith("image") ? (
        <LazyLoadImage
          src={`data:${media.contentType};base64,${arrayBufferToBase64(
            media.data.data
          )}`}
          alt={`img-${index}`}
          className="image"
        />
      ) : media.contentType === "video/mp4" ? (
        <LazyLoadComponent>
          <video className="video-content" autoPlay loop muted>
            <source
              src={`data:${media.contentType};base64,${arrayBufferToBase64(
                media.data.data
              )}`}
              type={"video/mp4"}
            />
            Your browser does not support the video tag.
          </video>
        </LazyLoadComponent>
      ) : (
        <div>Unsupported media type</div>
      )}
    </div>
  );
});

export default MediaItem
import React from 'react';
import baseMarmot1 from '../assets/pfpGenerator/0baselayer/marmots_base_1.png';
import baseMarmot2 from '../assets/pfpGenerator/0baselayer/marmots_base_2.png';

const BaseLayer = ({ baseLayer, setBaseLayer }) => {
  return (
    <div className="control-category">
      <label>Base Layer:</label>
      <div className="control-options">
        <img
          src={baseMarmot1}
          alt="Base Marmot 1"
          onClick={() => setBaseLayer(baseMarmot1)}
          className={baseLayer === baseMarmot1 ? 'selected' : ''}
        />
        <img
          src={baseMarmot2}
          alt="Base Marmot 2"
          onClick={() => setBaseLayer(baseMarmot2)}
          className={baseLayer === baseMarmot2 ? 'selected' : ''}
        />
      </div>
    </div>
  );
};

export default BaseLayer;

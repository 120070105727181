import React from 'react';
import './Marquee.css';

const Marquee = () => {
    return (
        <div className="marquee">
            <div className="track">
                <span>COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK   COOK  COOK   COOK   COOK   COOK   COOK   COOK   COOK  COOK   COOK   COOK   COOK   COOK   COOK   COOK  COOK   COOK   COOK   COOK   COOK   COOK   COOK  COOK   COOK   COOK   COOK   COOK   COOK   COOK   </span>
            </div>
        </div>
    );
}

export default Marquee;

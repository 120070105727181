import React, { useState } from 'react';
import LandingPage from './LandingPage';
import Intro from './Intro';
import MainPage from './MainPage';
import introVideo from './assets/videos/introVideo.mp4';
import backgroundVideo from './assets/videos/background.mp4';
import './App.css';

function App() {
  const [enterSite, setEnterSite] = useState(false);

  const handleEnter = () => {
    setEnterSite(true);
  };

  const handleSkipButtonClick = () => {
    console.log('Skip button clicked');
    // Add your logic here, for example, navigate to another page or section
  };

  return (
    <div className="App">
      {!enterSite ? (
        <LandingPage onEnter={handleEnter} />
      ) : (
        <>
          <Intro src={introVideo} onButtonClick={handleSkipButtonClick} />
          <MainPage src={backgroundVideo} />
        </>
      )}
    </div>
  );
}

export default App;

import React from 'react';
import emptyIcon from '../assets/pfpGenerator/empty_icon.png';
import leftAccessory1 from '../assets/pfpGenerator/left_marmot/accessories/left_acc_1.png';
import leftAccessory2 from '../assets/pfpGenerator/left_marmot/accessories/left_acc_2.png';
import leftAccessory3 from '../assets/pfpGenerator/left_marmot/accessories/left_acc_3.png';
import leftAccessory4 from '../assets/pfpGenerator/left_marmot/accessories/left_acc_4.png';
import leftAccessory5 from '../assets/pfpGenerator/left_marmot/accessories/left_acc_5.png';
import leftAccessory6 from '../assets/pfpGenerator/left_marmot/accessories/left_acc_6.png';
import leftAccessory7 from '../assets/pfpGenerator/left_marmot/accessories/left_acc_7.png';
import leftAccessory8 from '../assets/pfpGenerator/left_marmot/accessories/left_acc_8.png';
import leftAccessory9 from '../assets/pfpGenerator/left_marmot/accessories/left_acc_9.png';
import leftAccessory10 from '../assets/pfpGenerator/left_marmot/accessories/left_acc_10.png';
import leftAccessory11 from '../assets/pfpGenerator/left_marmot/accessories/left_acc_11.png';
import leftAccessory12 from '../assets/pfpGenerator/left_marmot/accessories/left_acc_12.png';
import leftAccessory13 from '../assets/pfpGenerator/left_marmot/accessories/left_acc_13.png';
import leftAccessory14 from '../assets/pfpGenerator/left_marmot/accessories/left_acc_14.png';

// Import other leftAccessory images...

const LeftAccessory = ({ leftAccessory, setLeftAccessory, scrollLeft, scrollRight, scrollRef }) => {
  return (
    <div className="control-category">
      <label>Left Accessory:</label>
      <div className="control-options-container">
        <button className="arrow arrow-left" onClick={() => scrollLeft('leftAccessory')}>{"<"}</button>
        <div className="control-options" ref={scrollRef}>
          <img
            src={emptyIcon}
            alt="None"
            onClick={() => setLeftAccessory(null)}
            className={leftAccessory === null ? 'selected' : ''}
          />
          <img
            src={leftAccessory1}
            alt="Left Accessory 1"
            onClick={() => setLeftAccessory(leftAccessory1)}
            className={leftAccessory === leftAccessory1 ? 'selected' : ''}
          />
          <img
            src={leftAccessory2}
            alt="Left Accessory 2"
            onClick={() => setLeftAccessory(leftAccessory2)}
            className={leftAccessory === leftAccessory2 ? 'selected' : ''}
          />
          <img
            src={leftAccessory3}
            alt="Left Accessory 3"
            onClick={() => setLeftAccessory(leftAccessory3)}
            className={leftAccessory === leftAccessory3 ? 'selected' : ''}
          />
          <img
            src={leftAccessory4}
            alt="Left Accessory 4"
            onClick={() => setLeftAccessory(leftAccessory4)}
            className={leftAccessory === leftAccessory4 ? 'selected' : ''}
          />
          <img
            src={leftAccessory5}
            alt="Left Accessory 5"
            onClick={() => setLeftAccessory(leftAccessory5)}
            className={leftAccessory === leftAccessory5 ? 'selected' : ''}
          />
          <img
            src={leftAccessory6}
            alt="Left Accessory 6"
            onClick={() => setLeftAccessory(leftAccessory6)}
            className={leftAccessory === leftAccessory6 ? 'selected' : ''}
          />
          <img
            src={leftAccessory7}
            alt="Left Accessory 7"
            onClick={() => setLeftAccessory(leftAccessory7)}
            className={leftAccessory === leftAccessory7 ? 'selected' : ''}
          />
          <img
            src={leftAccessory8}
            alt="Left Accessory 8"
            onClick={() => setLeftAccessory(leftAccessory8)}
            className={leftAccessory === leftAccessory8 ? 'selected' : ''}
          />
          <img
            src={leftAccessory9}
            alt="Left Accessory 9"
            onClick={() => setLeftAccessory(leftAccessory9)}
            className={leftAccessory === leftAccessory9 ? 'selected' : ''}
          />
          <img
            src={leftAccessory10}
            alt="Left Accessory 10"
            onClick={() => setLeftAccessory(leftAccessory10)}
            className={leftAccessory === leftAccessory10 ? 'selected' : ''}
          />
          <img
            src={leftAccessory11}
            alt="Left Accessory 11"
            onClick={() => setLeftAccessory(leftAccessory11)}
            className={leftAccessory === leftAccessory11 ? 'selected' : ''}
          />
          <img
            src={leftAccessory12}
            alt="Left Accessory 12"
            onClick={() => setLeftAccessory(leftAccessory12)}
            className={leftAccessory === leftAccessory12 ? 'selected' : ''}
          />
          <img
            src={leftAccessory13}
            alt="Left Accessory 13"
            onClick={() => setLeftAccessory(leftAccessory13)}
            className={leftAccessory === leftAccessory13 ? 'selected' : ''}
          />
          <img
            src={leftAccessory14}
            alt="Left Accessory 14"
            onClick={() => setLeftAccessory(leftAccessory14)}
            className={leftAccessory === leftAccessory14 ? 'selected' : ''}
          />
          {/* Add other leftAccessory images similarly */}
        </div>
        <button className="arrow arrow-right" onClick={() => scrollRight('leftAccessory')}>{">"}</button>
      </div>
    </div>
  );
};

export default LeftAccessory;

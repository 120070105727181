import React, { useEffect, useRef, useState } from 'react';
import './Intro.css';
import muteIcon from './assets/icons/mute3.png';
import unmuteIcon from './assets/icons/unmute3.png';

const Intro = ({ src, onButtonClick }) => {
  const videoRef = useRef(null); // Create a reference to the video element
  const [hideVideo, setHideVideo] = useState(false); // State to control video visibility
  const [isLoading, setIsLoading] = useState(true); // State to control loading overlay visibility
  const [isMuted, setIsMuted] = useState(false); // State to control video mute status

  // Effect to update the video volume based on the mute state
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = isMuted ? 0 : 0.2; // Set volume based on mute state
    }
  }, [isMuted]); // Re-run effect when mute state changes

  // Handler for the skip button click
  const handleButtonClick = () => {
    setHideVideo(true); // Hide the video
    setIsLoading(false); // Hide the loading overlay
    if (onButtonClick) {
      onButtonClick(); // Call the onButtonClick callback if provided
    }
  };

  // Handler for video loaded data event
  const handleVideoLoaded = () => {
    setIsLoading(false); // Hide the loading overlay when the video is loaded
    if (videoRef.current) {
      videoRef.current.play(); // Play the video
    }
  };

  // Handler to toggle mute state
  const toggleMute = () => {
    setIsMuted(!isMuted); // Toggle the mute state
  };

  // Handler to start the timer when the video starts playing
  const handleVideoPlay = () => {
    const timer = setTimeout(() => {
      setHideVideo(true); // Hide the video after 16 seconds
    }, 16000);
    return () => clearTimeout(timer); // Clear timer if component unmounts or effect re-runs
  };

  return (
    <div className="intro-container">
      {isLoading && <div className="loading-overlay"></div>}
      <video
        ref={videoRef}
        className={`videoTag introVideo ${hideVideo ? 'hide' : ''}`}
        preload="auto"
        loop
        playsInline
        muted={isMuted} // Mute the video based on state
        onLoadedData={handleVideoLoaded}
        onPlay={handleVideoPlay}
        style={{
          width: '100vw',
          height: '100vh',
        }}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {!hideVideo && (
        <>
          <button className="skip-button" onClick={handleButtonClick}>
            Skip Video
          </button>
        </>
      )}
      {/* Mute button */}
      <button className="mute-button" onClick={toggleMute}>
        <img src={isMuted ? muteIcon : unmuteIcon} alt={isMuted ? 'Mute' : 'Unmute'} />
      </button>
    </div>
  );
};

export default Intro;

import React, { useRef, useState, useEffect } from 'react';
import './PfpGenerator.css';
import BaseLayer from './PfpGeneratorComponents/BaseLayer';
import LeftHat from './PfpGeneratorComponents/LeftHat';
import LeftClothes from './PfpGeneratorComponents/LeftClothes';
import LeftAccessory from './PfpGeneratorComponents/LeftAccessory';
import RightHat from './PfpGeneratorComponents/RightHat';
import RightClothes from './PfpGeneratorComponents/RightClothes';
import RightAccessory from './PfpGeneratorComponents/RightAccessory';
import Background from './PfpGeneratorComponents/Background';

// Import base layer images
import baseMarmot1 from './assets/pfpGenerator/0baselayer/marmots_base_1.png';

const PfpGenerator = () => {
  const [baseLayer, setBaseLayer] = useState(baseMarmot1);
  const [leftClothes, setLeftClothes] = useState(null);
  const [leftHat, setLeftHat] = useState(null);
  const [leftAccessory, setLeftAccessory] = useState(null);
  const [rightClothes, setRightClothes] = useState(null);
  const [rightHat, setRightHat] = useState(null);
  const [rightAccessory, setRightAccessory] = useState(null);
  const [background, setBackground] = useState(null);
  const [customBackground, setCustomBackground] = useState(null);
  const [caption, setCaption] = useState('');
  const [scrollAmount, setScrollAmount] = useState(200);

  const canvasRef = useRef(null);
  const scrollRefs = {
    leftClothes: useRef(null),
    leftHat: useRef(null),
    leftAccessory: useRef(null),
    rightClothes: useRef(null),
    rightHat: useRef(null),
    rightAccessory: useRef(null),
    background: useRef(null),
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 576) {
        setScrollAmount(100);
      } else if (window.innerWidth <= 768) {
        setScrollAmount(240);
      } else if (window.innerWidth <= 992) {
        setScrollAmount(270);
      } else {
        setScrollAmount(360);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const drawImage = (src, z) => {
      return new Promise((resolve) => {
        if (!src) {
          resolve();
          return;
        }
        const image = new Image();
        image.src = src;
        image.onload = () => {
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          resolve();
        };
      });
    };

    const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
      const words = text.split(' ');
      let line = '';
      const lines = [];

      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + ' ';
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;

        if (testWidth > maxWidth && n > 0) {
          lines.push(line);
          line = words[n] + ' ';
        } else {
          line = testLine;
        }
      }
      lines.push(line);

      for (let k = 0; k < lines.length; k++) {
        context.fillText(lines[k], x, y + k * lineHeight);
      }

      return lines.length * lineHeight; // Return total height of text block
    };

    const drawImages = async () => {
      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw images in the fixed order
      await drawImage(customBackground || background, 0);
      await drawImage(baseLayer, 1);
      await drawImage(leftClothes, 2);
      await drawImage(leftHat, 3);
      await drawImage(leftAccessory, 4);
      await drawImage(rightClothes, 5);
      await drawImage(rightHat, 6);
      await drawImage(rightAccessory, 7);

      // Draw the caption
      if (caption) {
        ctx.font = '20px Arial';
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';
        const maxWidth = canvas.width - 40;
        const lineHeight = 24;  
        const x = canvas.width / 2;
        const y = canvas.height * 0.78;
        const textHeight = wrapText(ctx, caption, x, y, maxWidth, lineHeight);

        // Draw background for caption
        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // Black background with 0.5 opacity
        ctx.fillRect(0, y - lineHeight, canvas.width, textHeight + 10);
        ctx.fillStyle = 'white';
        wrapText(ctx, caption, x, y, maxWidth, lineHeight); // Redraw text on top of background
      }
    };

    drawImages();
  }, [background, customBackground, baseLayer, leftClothes, leftHat, leftAccessory, rightClothes, rightHat, rightAccessory, caption]);

  const saveImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const drawImage = (src, z) => {
      return new Promise((resolve) => {
        if (!src) {
          resolve();
          return;
        }
        const image = new Image();
        image.src = src;
        image.onload = () => {
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          resolve();
        };
      });
    };

    const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
      const words = text.split(' ');
      let line = '';
      const lines = [];

      for (let n = 0; n < words.length; n++) {
        const testLine = line + words[n] + ' ';
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;

        if (testWidth > maxWidth && n > 0) {
          lines.push(line);
          line = words[n] + ' ';
        } else {
          line = testLine;
        }
      }
      lines.push(line);

      for (let k = 0; k < lines.length; k++) {
        context.fillText(lines[k], x, y + k * lineHeight);
      }

      return lines.length * lineHeight; // Return total height of text block
    };

    const drawAndSave = async () => {
      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw images in the fixed order
      await drawImage(customBackground || background, 0);
      await drawImage(baseLayer, 1);
      await drawImage(leftClothes, 2);
      await drawImage(leftHat, 3);
      await drawImage(leftAccessory, 4);
      await drawImage(rightClothes, 5);
      await drawImage(rightHat, 6);
      await drawImage(rightAccessory, 7);

      // Draw the caption
      if (caption) {
        ctx.font = '20px Arial';
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';
        const maxWidth = canvas.width - 40;
        const lineHeight = 24;
        const x = canvas.width / 2;
        const y = canvas.height * 0.78;
        const textHeight = wrapText(ctx, caption, x, y, maxWidth, lineHeight);

        // Draw background for caption
        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // Black background with 0.5 opacity
        ctx.fillRect(0, y - lineHeight, canvas.width, textHeight + 10);
        ctx.fillStyle = 'white';
        wrapText(ctx, caption, x, y, maxWidth, lineHeight); // Redraw text on top of background
      }

      // Save image
      const imageUrl = canvas.toDataURL("image/png");
      const link = document.createElement('a');
      link.download = 'this_PFP_COOKS.png';
      link.href = imageUrl;
      link.click();
    };

    drawAndSave();
  };

  const handleBackgroundUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setCustomBackground(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const clearBackground = () => {
    setBackground(null);
    setCustomBackground(null);
  };

  const scrollLeft = (category) => {
    const scrollRef = scrollRefs[category].current;
    scrollRef.scrollLeft -= scrollAmount;
  };

  const scrollRight = (category) => {
    const scrollRef = scrollRefs[category].current;
    scrollRef.scrollLeft += scrollAmount;
  };

  return (
    <div className="pfp-generator">
      <div className="pfp-preview">
        {customBackground && <img src={customBackground} alt="Custom Background" className="overlay background" />}
        {background && !customBackground && <img src={background} alt="Background" className="overlay background" />}
        <img src={baseLayer} alt="Base Marmot" className="base-image" />
        {leftClothes && <img src={leftClothes} alt="Left Clothes" className="overlay left-clothes" />}
        {leftHat && <img src={leftHat} alt="Left Hat" className="overlay left-hat" />}
        {leftAccessory && <img src={leftAccessory} alt="Left Accessory" className="overlay left-accessory" />}
        {rightClothes && <img src={rightClothes} alt="Right Clothes" className="overlay right-clothes" />}
        {rightHat && <img src={rightHat} alt="Right Hat" className="overlay right-hat" />}
        {rightAccessory && <img src={rightAccessory} alt="Right Accessory" className="overlay right-accessory" />}
        {caption && <div className="caption">{caption}</div>}
      </div>
      <canvas ref={canvasRef} width="600" height="600" style={{ display: 'none' }}></canvas>
      <div className="controls">
        <BaseLayer baseLayer={baseLayer} setBaseLayer={setBaseLayer} />
        <RightClothes rightClothes={rightClothes} setRightClothes={setRightClothes} scrollLeft={scrollLeft} scrollRight={scrollRight} scrollRef={scrollRefs.rightClothes} />
        <RightHat rightHat={rightHat} setRightHat={setRightHat} scrollLeft={scrollLeft} scrollRight={scrollRight} scrollRef={scrollRefs.rightHat} />
        <RightAccessory rightAccessory={rightAccessory} setRightAccessory={setRightAccessory} scrollLeft={scrollLeft} scrollRight={scrollRight} scrollRef={scrollRefs.rightAccessory} />
        <LeftClothes leftClothes={leftClothes} setLeftClothes={setLeftClothes} scrollLeft={scrollLeft} scrollRight={scrollRight} scrollRef={scrollRefs.leftClothes} />
        <LeftHat leftHat={leftHat} setLeftHat={setLeftHat} scrollLeft={scrollLeft} scrollRight={scrollRight} scrollRef={scrollRefs.leftHat} />
        <LeftAccessory leftAccessory={leftAccessory} setLeftAccessory={setLeftAccessory} scrollLeft={scrollLeft} scrollRight={scrollRight} scrollRef={scrollRefs.leftAccessory} />
        <Background background={background} customBackground={customBackground} setBackground={setBackground} clearBackground={clearBackground} scrollLeft={scrollLeft} scrollRight={scrollRight} scrollRef={scrollRefs.background} handleBackgroundUpload={handleBackgroundUpload} />
        <input 
          type="text" 
          placeholder="Enter caption" 
          value={caption} 
          onChange={(e) => setCaption(e.target.value)} 
          className="caption-input" 
        />
        <button onClick={saveImage}>Save Image</button>
      </div>
    </div>
  );
};

export default PfpGenerator;
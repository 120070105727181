import React, { useRef, useEffect } from 'react';
import './VideoBackground.css';

const VideoBackground = ({ src }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play().catch(error => console.log('Error playing video:', error));
        }
    }, []);

    return (
        <div className="video-background">
            <video
                ref={videoRef}
                className='videoTag'
                preload="auto"
                autoPlay
                loop
                muted
                playsInline
            >
                <source src={src} type='video/mp4' />
            </video>
        </div>
    );
};

export default VideoBackground;

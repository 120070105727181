import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
import MediaItem from "./MemeGalleryComponents/MediaItem";
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import "./MemeGallery.css";

const MemeGallery = () => {
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const galleryRef = useRef(null);

  const fetchMedia = useCallback(async (pageNum) => {
    if (loading) return;

    setLoading(true);

    try {
      const response = await axios.get(`/images?page=${pageNum}&limit=12`);
      const { data, total } = response.data;

      setImages(data);
      setPage(pageNum)

      if (pageNum * 12 >= total) {
        setHasMore(false);
      } else {
        if (!hasMore) {
          setHasMore(true)
        }
      }

    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error("Error fetching images:", error);
      }
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore]);

  const loadNextMedia = () => {
    if (hasMore && !loading) {
      fetchMedia(page + 1);
    }
  };

  const loadPreviousMedia = () => {
    if (page > 1 && !loading) {
      fetchMedia(page - 1);
    }
  };

  // Attach observer to meme gallery to load media when it enters viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !initialized) {
          fetchMedia(1);
          setInitialized(true);
        }
      },
      { threshold: 0.1 }
    );

    if (galleryRef.current) {
      observer.observe(galleryRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [initialized, fetchMedia]);

  return (
    <div className="main-page-contents contents-2" ref={galleryRef}>
      <h1 style={{marginTop: '8px'}}>Memes</h1>
      {loading ? (
        <h2 style={{ margin: 'auto' }}>Cooking memes...</h2>
      ) : (
        <ResponsiveMasonry
          className="image-container"
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1050: 4 }}
        >
          <Masonry gutter="10px">
            {images.map((media, index) => (
              <MediaItem key={index} media={media} index={index} />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      )}
      <div className="button-container">
        <button
          className="button"
          onClick={loadPreviousMedia}
          disabled={page === 1 || loading}
        >
          Previous
        </button>
        <button
          className="button"
          onClick={loadNextMedia}
          disabled={!hasMore || loading}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MemeGallery;
import React from 'react';
import emptyIcon from '../assets/pfpGenerator/empty_icon.png';
import rightHat1 from '../assets/pfpGenerator/right_marmot/hats/right_hat_1.png';
import rightHat2 from '../assets/pfpGenerator/right_marmot/hats/right_hat_2.png';
import rightHat3 from '../assets/pfpGenerator/right_marmot/hats/right_hat_3.png';
import rightHat4 from '../assets/pfpGenerator/right_marmot/hats/right_hat_4.png';
import rightHat5 from '../assets/pfpGenerator/right_marmot/hats/right_hat_5.png';
import rightHat6 from '../assets/pfpGenerator/right_marmot/hats/right_hat_6.png';
import rightHat7 from '../assets/pfpGenerator/right_marmot/hats/right_hat_7.png';
import rightHat8 from '../assets/pfpGenerator/right_marmot/hats/right_hat_8.png';
import rightHat9 from '../assets/pfpGenerator/right_marmot/hats/right_hat_9.png';
import rightHat10 from '../assets/pfpGenerator/right_marmot/hats/right_hat_10.png';
import rightHat11 from '../assets/pfpGenerator/right_marmot/hats/right_hat_11.png';
import rightHat12 from '../assets/pfpGenerator/right_marmot/hats/right_hat_12.png';
import rightHat13 from '../assets/pfpGenerator/right_marmot/hats/right_hat_13.png';
import rightHat14 from '../assets/pfpGenerator/right_marmot/hats/right_hat_14.png';
import rightHat15 from '../assets/pfpGenerator/right_marmot/hats/right_hat_15.png';
import rightHat16 from '../assets/pfpGenerator/right_marmot/hats/right_hat_16.png';
import rightHat17 from '../assets/pfpGenerator/right_marmot/hats/right_hat_17.png';
import rightHat18 from '../assets/pfpGenerator/right_marmot/hats/right_hat_18.png';
import rightHat19 from '../assets/pfpGenerator/right_marmot/hats/right_hat_19.png';
import rightHat20 from '../assets/pfpGenerator/right_marmot/hats/right_hat_20.png';
import rightHat21 from '../assets/pfpGenerator/right_marmot/hats/right_hat_21.png';
import rightHat22 from '../assets/pfpGenerator/right_marmot/hats/right_hat_22.png';
import rightHat23 from '../assets/pfpGenerator/right_marmot/hats/right_hat_23.png';
import rightHat24 from '../assets/pfpGenerator/right_marmot/hats/right_hat_24.png';
import rightHat25 from '../assets/pfpGenerator/right_marmot/hats/right_hat_25.png';
import rightHat26 from '../assets/pfpGenerator/right_marmot/hats/right_hat_26.png';
import rightHat27 from '../assets/pfpGenerator/right_marmot/hats/right_hat_27.png';
import rightHat28 from '../assets/pfpGenerator/right_marmot/hats/right_hat_28.png';
import rightHat29 from '../assets/pfpGenerator/right_marmot/hats/right_hat_29.png';
import rightHat30 from '../assets/pfpGenerator/right_marmot/hats/right_hat_30.png';
import rightHat31 from '../assets/pfpGenerator/right_marmot/hats/right_hat_31.png';
import rightHat32 from '../assets/pfpGenerator/right_marmot/hats/right_hat_32.png';
import rightHat33 from '../assets/pfpGenerator/right_marmot/hats/right_hat_33.png';
import rightHat34 from '../assets/pfpGenerator/right_marmot/hats/right_hat_34.png';



// Import other rightHat images...

const RightHat = ({ rightHat, setRightHat, scrollLeft, scrollRight, scrollRef }) => {
  return (
    <div className="control-category">
      <label>Right Hat:</label>
      <div className="control-options-container">
        <button className="arrow arrow-left" onClick={() => scrollLeft('rightHat')}>{"<"}</button>
        <div className="control-options" ref={scrollRef}>
          <img
            src={emptyIcon}
            alt="None"
            onClick={() => setRightHat(null)}
            className={rightHat === null ? 'selected' : ''}
          />
          <img
            src={rightHat1}
            alt="Right Hat 1"
            onClick={() => setRightHat(rightHat1)}
            className={rightHat === rightHat1 ? 'selected' : ''}
          />
          <img
            src={rightHat2}
            alt="Right Hat 2"
            onClick={() => setRightHat(rightHat2)}
            className={rightHat === rightHat2 ? 'selected' : ''}
          />
          <img
            src={rightHat3}
            alt="Right Hat 3"
            onClick={() => setRightHat(rightHat3)}
            className={rightHat === rightHat3 ? 'selected' : ''}
          />
          <img
            src={rightHat4}
            alt="Right Hat 4"
            onClick={() => setRightHat(rightHat4)}
            className={rightHat === rightHat4 ? 'selected' : ''}
          />
          <img
            src={rightHat5}
            alt="Right Hat 5"
            onClick={() => setRightHat(rightHat5)}
            className={rightHat === rightHat5 ? 'selected' : ''}
          />
          <img
            src={rightHat6}
            alt="Right Hat 6"
            onClick={() => setRightHat(rightHat6)}
            className={rightHat === rightHat6 ? 'selected' : ''}
          />
          <img
            src={rightHat7}
            alt="Right Hat 7"
            onClick={() => setRightHat(rightHat7)}
            className={rightHat === rightHat7 ? 'selected' : ''}
          />
          <img
            src={rightHat8}
            alt="Right Hat 8"
            onClick={() => setRightHat(rightHat8)}
            className={rightHat === rightHat8 ? 'selected' : ''}
          />
          <img
            src={rightHat9}
            alt="Right Hat 9"
            onClick={() => setRightHat(rightHat9)}
            className={rightHat === rightHat9 ? 'selected' : ''}
          />
          <img
            src={rightHat10}
            alt="Right Hat 10"
            onClick={() => setRightHat(rightHat10)}
            className={rightHat === rightHat10 ? 'selected' : ''}
          />
          <img
            src={rightHat11}
            alt="Right Hat 11"
            onClick={() => setRightHat(rightHat11)}
            className={rightHat === rightHat11 ? 'selected' : ''}
          />
          <img
            src={rightHat12}
            alt="Right Hat 12"
            onClick={() => setRightHat(rightHat12)}
            className={rightHat === rightHat12 ? 'selected' : ''}
          />
          <img
            src={rightHat13}
            alt="Right Hat 13"
            onClick={() => setRightHat(rightHat13)}
            className={rightHat === rightHat13 ? 'selected' : ''}
          />
          <img
            src={rightHat14}
            alt="Right Hat 14"
            onClick={() => setRightHat(rightHat14)}
            className={rightHat === rightHat14 ? 'selected' : ''}
          />
          <img
            src={rightHat15}
            alt="Right Hat 15"
            onClick={() => setRightHat(rightHat15)}
            className={rightHat === rightHat15 ? 'selected' : ''}
          />
          <img
            src={rightHat16}
            alt="Right Hat 16"
            onClick={() => setRightHat(rightHat16)}
            className={rightHat === rightHat16 ? 'selected' : ''}
          />
          <img
            src={rightHat17}
            alt="Right Hat 17"
            onClick={() => setRightHat(rightHat17)}
            className={rightHat === rightHat17 ? 'selected' : ''}
          />
          <img
            src={rightHat18}
            alt="Right Hat 18"
            onClick={() => setRightHat(rightHat18)}
            className={rightHat === rightHat18 ? 'selected' : ''}
          />
          <img
            src={rightHat19}
            alt="Right Hat 19"
            onClick={() => setRightHat(rightHat19)}
            className={rightHat === rightHat19 ? 'selected' : ''}
          />
          <img
            src={rightHat20}
            alt="Right Hat 20"
            onClick={() => setRightHat(rightHat20)}
            className={rightHat === rightHat20 ? 'selected' : ''}
          />
          <img
            src={rightHat21}
            alt="Right Hat 21"
            onClick={() => setRightHat(rightHat21)}
            className={rightHat === rightHat21 ? 'selected' : ''}
          />
          <img
            src={rightHat22}
            alt="Right Hat 22"
            onClick={() => setRightHat(rightHat22)}
            className={rightHat === rightHat22 ? 'selected' : ''}
          />
          <img
            src={rightHat23}
            alt="Right Hat 23"
            onClick={() => setRightHat(rightHat23)}
            className={rightHat === rightHat23 ? 'selected' : ''}
          />
          <img
            src={rightHat24}
            alt="Right Hat 24"
            onClick={() => setRightHat(rightHat24)}
            className={rightHat === rightHat24 ? 'selected' : ''}
          />
          <img
            src={rightHat25}
            alt="Right Hat 25"
            onClick={() => setRightHat(rightHat25)}
            className={rightHat === rightHat25 ? 'selected' : ''}
          />
          <img
            src={rightHat26}
            alt="Right Hat 26"
            onClick={() => setRightHat(rightHat26)}
            className={rightHat === rightHat26 ? 'selected' : ''}
          />
          <img
            src={rightHat27}
            alt="Right Hat 27"
            onClick={() => setRightHat(rightHat27)}
            className={rightHat === rightHat27 ? 'selected' : ''}
          />
          <img
            src={rightHat28}
            alt="Right Hat 28"
            onClick={() => setRightHat(rightHat28)}
            className={rightHat === rightHat28 ? 'selected' : ''}
          />
          <img
            src={rightHat29}
            alt="Right Hat 29"
            onClick={() => setRightHat(rightHat29)}
            className={rightHat === rightHat29 ? 'selected' : ''}
          />
          <img
            src={rightHat30}
            alt="Right Hat 30"
            onClick={() => setRightHat(rightHat30)}
            className={rightHat === rightHat30 ? 'selected' : ''}
          />
          <img
            src={rightHat31}
            alt="Right Hat 31"
            onClick={() => setRightHat(rightHat31)}
            className={rightHat === rightHat31 ? 'selected' : ''}
          />
          <img
            src={rightHat32}
            alt="Right Hat 32"
            onClick={() => setRightHat(rightHat32)}
            className={rightHat === rightHat32 ? 'selected' : ''}
          />
          <img
            src={rightHat33}
            alt="Right Hat 33"
            onClick={() => setRightHat(rightHat33)}
            className={rightHat === rightHat33 ? 'selected' : ''}
          />
          <img
            src={rightHat34}
            alt="Right Hat 34"
            onClick={() => setRightHat(rightHat34)}
            className={rightHat === rightHat34 ? 'selected' : ''}
          />
          {/* Add other rightHat images similarly */}
        </div>
        <button className="arrow arrow-right" onClick={() => scrollRight('rightHat')}>{">"}</button>
      </div>
    </div>
  );
};

export default RightHat;

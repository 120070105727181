import React from 'react';
import emptyIcon from '../assets/pfpGenerator/empty_icon.png';
import leftHat1 from '../assets/pfpGenerator/left_marmot/hats/left_hat_1.png';
import leftHat2 from '../assets/pfpGenerator/left_marmot/hats/left_hat_2.png';
import leftHat3 from '../assets/pfpGenerator/left_marmot/hats/left_hat_3.png';
import leftHat4 from '../assets/pfpGenerator/left_marmot/hats/left_hat_4.png';
import leftHat5 from '../assets/pfpGenerator/left_marmot/hats/left_hat_5.png';
import leftHat6 from '../assets/pfpGenerator/left_marmot/hats/left_hat_6.png';
import leftHat7 from '../assets/pfpGenerator/left_marmot/hats/left_hat_7.png';
import leftHat8 from '../assets/pfpGenerator/left_marmot/hats/left_hat_8.png';
import leftHat9 from '../assets/pfpGenerator/left_marmot/hats/left_hat_9.png';
import leftHat10 from '../assets/pfpGenerator/left_marmot/hats/left_hat_10.png';
import leftHat11 from '../assets/pfpGenerator/left_marmot/hats/left_hat_11.png';
import leftHat12 from '../assets/pfpGenerator/left_marmot/hats/left_hat_12.png';
import leftHat13 from '../assets/pfpGenerator/left_marmot/hats/left_hat_13.png';
import leftHat14 from '../assets/pfpGenerator/left_marmot/hats/left_hat_14.png';
import leftHat15 from '../assets/pfpGenerator/left_marmot/hats/left_hat_15.png';
import leftHat16 from '../assets/pfpGenerator/left_marmot/hats/left_hat_16.png';
import leftHat17 from '../assets/pfpGenerator/left_marmot/hats/left_hat_17.png';
import leftHat18 from '../assets/pfpGenerator/left_marmot/hats/left_hat_18.png';
import leftHat19 from '../assets/pfpGenerator/left_marmot/hats/left_hat_19.png';
import leftHat20 from '../assets/pfpGenerator/left_marmot/hats/left_hat_20.png';
import leftHat21 from '../assets/pfpGenerator/left_marmot/hats/left_hat_21.png';
import leftHat22 from '../assets/pfpGenerator/left_marmot/hats/left_hat_22.png';
import leftHat23 from '../assets/pfpGenerator/left_marmot/hats/left_hat_23.png';
import leftHat24 from '../assets/pfpGenerator/left_marmot/hats/left_hat_24.png';
import leftHat25 from '../assets/pfpGenerator/left_marmot/hats/left_hat_25.png';
import leftHat26 from '../assets/pfpGenerator/left_marmot/hats/left_hat_26.png';
import leftHat27 from '../assets/pfpGenerator/left_marmot/hats/left_hat_27.png';
import leftHat28 from '../assets/pfpGenerator/left_marmot/hats/left_hat_28.png';
import leftHat29 from '../assets/pfpGenerator/left_marmot/hats/left_hat_29.png';
import leftHat30 from '../assets/pfpGenerator/left_marmot/hats/left_hat_30.png';
import leftHat31 from '../assets/pfpGenerator/left_marmot/hats/left_hat_31.png';

const LeftHat = ({ leftHat, setLeftHat, scrollLeft, scrollRight, scrollRef }) => {
  return (
    <div className="control-category">
      <label>Left Hat:</label>
      <div className="control-options-container">
        <button className="arrow arrow-left" onClick={() => scrollLeft('leftHat')}>{"<"}</button>
        <div className="control-options" ref={scrollRef}>
          <img
            src={emptyIcon}
            alt="None"
            onClick={() => setLeftHat(null)}
            className={leftHat === null ? 'selected' : ''}
          />
          <img
            src={leftHat1}
            alt="Left Hat 1"
            onClick={() => setLeftHat(leftHat1)}
            className={leftHat === leftHat1 ? 'selected' : ''}
          />
          <img
            src={leftHat2}
            alt="Left Hat 2"
            onClick={() => setLeftHat(leftHat2)}
            className={leftHat === leftHat2 ? 'selected' : ''}
          />
          <img
            src={leftHat3}
            alt="Left Hat 3"
            onClick={() => setLeftHat(leftHat3)}
            className={leftHat === leftHat3 ? 'selected' : ''}
          />
          <img
            src={leftHat4}
            alt="Left Hat 4"
            onClick={() => setLeftHat(leftHat4)}
            className={leftHat === leftHat4 ? 'selected' : ''}
          />
          <img
            src={leftHat5}
            alt="Left Hat 5"
            onClick={() => setLeftHat(leftHat5)}
            className={leftHat === leftHat5 ? 'selected' : ''}
          />
          <img
            src={leftHat6}
            alt="Left Hat 6"
            onClick={() => setLeftHat(leftHat6)}
            className={leftHat === leftHat6 ? 'selected' : ''}
          />
          <img
            src={leftHat7}
            alt="Left Hat 7"
            onClick={() => setLeftHat(leftHat7)}
            className={leftHat === leftHat7 ? 'selected' : ''}
          />
          <img
            src={leftHat8}
            alt="Left Hat 8"
            onClick={() => setLeftHat(leftHat8)}
            className={leftHat === leftHat8 ? 'selected' : ''}
          />
          <img
            src={leftHat9}
            alt="Left Hat 9"
            onClick={() => setLeftHat(leftHat9)}
            className={leftHat === leftHat9 ? 'selected' : ''}
          />
          <img
            src={leftHat10}
            alt="Left Hat 10"
            onClick={() => setLeftHat(leftHat10)}
            className={leftHat === leftHat10 ? 'selected' : ''}
          />
          <img
            src={leftHat11}
            alt="Left Hat 11"
            onClick={() => setLeftHat(leftHat11)}
            className={leftHat === leftHat11 ? 'selected' : ''}
          />
          <img
            src={leftHat12}
            alt="Left Hat 12"
            onClick={() => setLeftHat(leftHat12)}
            className={leftHat === leftHat12 ? 'selected' : ''}
          />
          <img
            src={leftHat13}
            alt="Left Hat 13"
            onClick={() => setLeftHat(leftHat13)}
            className={leftHat === leftHat13 ? 'selected' : ''}
          />
          <img
            src={leftHat14}
            alt="Left Hat 14"
            onClick={() => setLeftHat(leftHat14)}
            className={leftHat === leftHat14 ? 'selected' : ''}
          />
          <img
            src={leftHat15}
            alt="Left Hat 15"
            onClick={() => setLeftHat(leftHat15)}
            className={leftHat === leftHat15 ? 'selected' : ''}
          />
          <img
            src={leftHat16}
            alt="Left Hat 16"
            onClick={() => setLeftHat(leftHat16)}
            className={leftHat === leftHat16 ? 'selected' : ''}
          />
          <img
            src={leftHat17}
            alt="Left Hat 17"
            onClick={() => setLeftHat(leftHat17)}
            className={leftHat === leftHat17 ? 'selected' : ''}
          />
          <img
            src={leftHat18}
            alt="Left Hat 18"
            onClick={() => setLeftHat(leftHat18)}
            className={leftHat === leftHat18 ? 'selected' : ''}
          />
          <img
            src={leftHat19}
            alt="Left Hat 19"
            onClick={() => setLeftHat(leftHat19)}
            className={leftHat === leftHat19 ? 'selected' : ''}
          />
          <img
            src={leftHat20}
            alt="Left Hat 20"
            onClick={() => setLeftHat(leftHat20)}
            className={leftHat === leftHat20 ? 'selected' : ''}
          />
          <img
            src={leftHat21}
            alt="Left Hat 21"
            onClick={() => setLeftHat(leftHat21)}
            className={leftHat === leftHat21 ? 'selected' : ''}
          />
          <img
            src={leftHat22}
            alt="Left Hat 22"
            onClick={() => setLeftHat(leftHat22)}
            className={leftHat === leftHat22 ? 'selected' : ''}
          />
          <img
            src={leftHat23}
            alt="Left Hat 23"
            onClick={() => setLeftHat(leftHat23)}
            className={leftHat === leftHat23 ? 'selected' : ''}
          />
          <img
            src={leftHat24}
            alt="Left Hat 24"
            onClick={() => setLeftHat(leftHat24)}
            className={leftHat === leftHat24 ? 'selected' : ''}
          />
          <img
            src={leftHat25}
            alt="Left Hat 25"
            onClick={() => setLeftHat(leftHat25)}
            className={leftHat === leftHat25 ? 'selected' : ''}
          />
          <img
            src={leftHat26}
            alt="Left Hat 26"
            onClick={() => setLeftHat(leftHat26)}
            className={leftHat === leftHat26 ? 'selected' : ''}
          />
          <img
            src={leftHat27}
            alt="Left Hat 27"
            onClick={() => setLeftHat(leftHat27)}
            className={leftHat === leftHat27 ? 'selected' : ''}
          />
          <img
            src={leftHat28}
            alt="Left Hat 28"
            onClick={() => setLeftHat(leftHat28)}
            className={leftHat === leftHat28 ? 'selected' : ''}
          />
          <img
            src={leftHat29}
            alt="Left Hat 29"
            onClick={() => setLeftHat(leftHat29)}
            className={leftHat === leftHat29 ? 'selected' : ''}
          />
          <img
            src={leftHat30}
            alt="Left Hat 30"
            onClick={() => setLeftHat(leftHat30)}
            className={leftHat === leftHat30 ? 'selected' : ''}
          />
          <img
            src={leftHat31}
            alt="Left Hat 31"
            onClick={() => setLeftHat(leftHat31)}
            className={leftHat === leftHat31 ? 'selected' : ''}
          />
        </div>
        <button className="arrow arrow-right" onClick={() => scrollRight('leftHat')}>{">"}</button>
      </div>
    </div>
  );
};

export default LeftHat;

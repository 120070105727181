import React from 'react';
import emptyIcon from '../assets/pfpGenerator/empty_icon.png';
import rightAccessory1 from '../assets/pfpGenerator/right_marmot/accessories/right_acc_1.png';
import rightAccessory2 from '../assets/pfpGenerator/right_marmot/accessories/right_acc_2.png';
import rightAccessory3 from '../assets/pfpGenerator/right_marmot/accessories/right_acc_3.png';
import rightAccessory4 from '../assets/pfpGenerator/right_marmot/accessories/right_acc_4.png';
import rightAccessory5 from '../assets/pfpGenerator/right_marmot/accessories/right_acc_5.png';
import rightAccessory6 from '../assets/pfpGenerator/right_marmot/accessories/right_acc_6.png';
import rightAccessory7 from '../assets/pfpGenerator/right_marmot/accessories/right_acc_7.png';
import rightAccessory8 from '../assets/pfpGenerator/right_marmot/accessories/right_acc_8.png';
import rightAccessory9 from '../assets/pfpGenerator/right_marmot/accessories/right_acc_9.png';
import rightAccessory10 from '../assets/pfpGenerator/right_marmot/accessories/right_acc_10.png';
import rightAccessory11 from '../assets/pfpGenerator/right_marmot/accessories/right_acc_11.png';

// Import other rightAccessory images...

const RightAccessory = ({ rightAccessory, setRightAccessory, scrollLeft, scrollRight, scrollRef }) => {
  return (
    <div className="control-category">
      <label>Right Accessory:</label>
      <div className="control-options-container">
        <button className="arrow arrow-left" onClick={() => scrollLeft('rightAccessory')}>{"<"}</button>
        <div className="control-options" ref={scrollRef}>
          <img
            src={emptyIcon}
            alt="None"
            onClick={() => setRightAccessory(null)}
            className={rightAccessory === null ? 'selected' : ''}
          />
          <img
            src={rightAccessory1}
            alt="Right Accessory 1"
            onClick={() => setRightAccessory(rightAccessory1)}
            className={rightAccessory === rightAccessory1 ? 'selected' : ''}
          />
          <img
            src={rightAccessory2}
            alt="Right Accessory 2"
            onClick={() => setRightAccessory(rightAccessory2)}
            className={rightAccessory === rightAccessory2 ? 'selected' : ''}
          />
          <img
            src={rightAccessory3}
            alt="Right Accessory 3"
            onClick={() => setRightAccessory(rightAccessory3)}
            className={rightAccessory === rightAccessory3 ? 'selected' : ''}
          />
          <img
            src={rightAccessory4}
            alt="Right Accessory 4"
            onClick={() => setRightAccessory(rightAccessory4)}
            className={rightAccessory === rightAccessory4 ? 'selected' : ''}
          />
          <img
            src={rightAccessory5}
            alt="Right Accessory 5"
            onClick={() => setRightAccessory(rightAccessory5)}
            className={rightAccessory === rightAccessory5 ? 'selected' : ''}
          />
          <img
            src={rightAccessory6}
            alt="Right Accessory 6"
            onClick={() => setRightAccessory(rightAccessory6)}
            className={rightAccessory === rightAccessory6 ? 'selected' : ''}
          />
          <img
            src={rightAccessory7}
            alt="Right Accessory 7"
            onClick={() => setRightAccessory(rightAccessory7)}
            className={rightAccessory === rightAccessory7 ? 'selected' : ''}
          />
          <img
            src={rightAccessory8}
            alt="Right Accessory 8"
            onClick={() => setRightAccessory(rightAccessory8)}
            className={rightAccessory === rightAccessory8 ? 'selected' : ''}
          />
          <img
            src={rightAccessory9}
            alt="Right Accessory 9"
            onClick={() => setRightAccessory(rightAccessory9)}
            className={rightAccessory === rightAccessory9 ? 'selected' : ''}
          />
          <img
            src={rightAccessory10}
            alt="Right Accessory 10"
            onClick={() => setRightAccessory(rightAccessory10)}
            className={rightAccessory === rightAccessory10 ? 'selected' : ''}
          />
          <img
            src={rightAccessory11}
            alt="Right Accessory 11"
            onClick={() => setRightAccessory(rightAccessory11)}
            className={rightAccessory === rightAccessory11 ? 'selected' : ''}
          />
          {/* Add more rightAccessory options as needed */}
        </div>
        <button className="arrow arrow-right" onClick={() => scrollRight('rightAccessory')}>{">"}</button>
      </div>
    </div>
  );
};

export default RightAccessory;

import React from 'react';
import emptyIcon from '../assets/pfpGenerator/empty_icon.png';
import leftClothes1 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_1.png';
import leftClothes2 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_2.png';
import leftClothes3 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_3.png';
import leftClothes4 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_4.png';
import leftClothes5 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_5.png';
import leftClothes6 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_6.png';
import leftClothes7 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_7.png';
import leftClothes8 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_8.png';
import leftClothes9 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_9.png';
import leftClothes10 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_10.png';
import leftClothes11 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_11.png';
import leftClothes12 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_12.png';
import leftClothes13 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_13.png';
import leftClothes14 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_14.png';
import leftClothes15 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_15.png';
import leftClothes16 from '../assets/pfpGenerator/left_marmot/clothes/left_clothes_16.png';
// Import other leftClothes images...

const LeftClothes = ({ leftClothes, setLeftClothes, scrollLeft, scrollRight, scrollRef }) => {
  return (
    <div className="control-category">
      <label>Left Clothes:</label>
      <div className="control-options-container">
        <button className="arrow arrow-left" onClick={() => scrollLeft('leftClothes')}>{"<"}</button>
        <div className="control-options" ref={scrollRef}>
          <img
            src={emptyIcon}
            alt="None"
            onClick={() => setLeftClothes(null)}
            className={leftClothes === null ? 'selected' : ''}
          />
          <img
            src={leftClothes1}
            alt="Left Clothes 1"
            onClick={() => setLeftClothes(leftClothes1)}
            className={leftClothes === leftClothes1 ? 'selected' : ''}
          />
          <img
            src={leftClothes2}
            alt="Left Clothes 2"
            onClick={() => setLeftClothes(leftClothes2)}
            className={leftClothes === leftClothes2 ? 'selected' : ''}
          />
          <img
            src={leftClothes3}
            alt="Left Clothes 3"
            onClick={() => setLeftClothes(leftClothes3)}
            className={leftClothes === leftClothes3 ? 'selected' : ''}
          />
          <img
            src={leftClothes4}
            alt="Left Clothes 4"
            onClick={() => setLeftClothes(leftClothes4)}
            className={leftClothes === leftClothes4 ? 'selected' : ''}
          />
          <img
            src={leftClothes5}
            alt="Left Clothes 5"
            onClick={() => setLeftClothes(leftClothes5)}
            className={leftClothes === leftClothes5 ? 'selected' : ''}
          />
          <img
            src={leftClothes6}
            alt="Left Clothes 6"
            onClick={() => setLeftClothes(leftClothes6)}
            className={leftClothes === leftClothes6 ? 'selected' : ''}
          />
          <img
            src={leftClothes7}
            alt="Left Clothes 7"
            onClick={() => setLeftClothes(leftClothes7)}
            className={leftClothes === leftClothes7 ? 'selected' : ''}
          />
          <img
            src={leftClothes8}
            alt="Left Clothes 8"
            onClick={() => setLeftClothes(leftClothes8)}
            className={leftClothes === leftClothes8 ? 'selected' : ''}
          />
          <img
            src={leftClothes9}
            alt="Left Clothes 9"
            onClick={() => setLeftClothes(leftClothes9)}
            className={leftClothes === leftClothes9 ? 'selected' : ''}
          />
          <img
            src={leftClothes10}
            alt="Left Clothes 10"
            onClick={() => setLeftClothes(leftClothes10)}
            className={leftClothes === leftClothes10 ? 'selected' : ''}
          />
          <img
            src={leftClothes11}
            alt="Left Clothes 11"
            onClick={() => setLeftClothes(leftClothes11)}
            className={leftClothes === leftClothes11 ? 'selected' : ''}
          />
          <img
            src={leftClothes12}
            alt="Left Clothes 12"
            onClick={() => setLeftClothes(leftClothes12)}
            className={leftClothes === leftClothes12 ? 'selected' : ''}
          />
          <img
            src={leftClothes13}
            alt="Left Clothes 13"
            onClick={() => setLeftClothes(leftClothes13)}
            className={leftClothes === leftClothes13 ? 'selected' : ''}
          />
          <img
            src={leftClothes14}
            alt="Left Clothes 14"
            onClick={() => setLeftClothes(leftClothes14)}
            className={leftClothes === leftClothes14 ? 'selected' : ''}
          />
          <img
            src={leftClothes15}
            alt="Left Clothes 15"
            onClick={() => setLeftClothes(leftClothes15)}
            className={leftClothes === leftClothes15 ? 'selected' : ''}
          />
          <img
            src={leftClothes16}
            alt="Left Clothes 16"
            onClick={() => setLeftClothes(leftClothes16)}
            className={leftClothes === leftClothes16 ? 'selected' : ''}
          />
          {/* Add other leftClothes images similarly */}
        </div>
        <button className="arrow arrow-right" onClick={() => scrollRight('leftClothes')}>{">"}</button>
      </div>
    </div>
  );
};

export default LeftClothes;

import React from 'react';
import emptyIcon from '../assets/pfpGenerator/empty_icon.png';
import rightClothes1 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_1.png';
import rightClothes2 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_2.png';
import rightClothes3 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_3.png';
import rightClothes4 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_4.png';
import rightClothes5 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_5.png';
import rightClothes6 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_6.png';
import rightClothes7 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_7.png';
import rightClothes8 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_8.png';
import rightClothes9 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_9.png';
import rightClothes10 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_10.png';
import rightClothes11 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_11.png';
import rightClothes12 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_12.png';
import rightClothes13 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_13.png';
import rightClothes14 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_14.png';
import rightClothes15 from '../assets/pfpGenerator/right_marmot/clothes/right_clothes_15.png';

// Import other rightClothes images...

const RightClothes = ({ rightClothes, setRightClothes, scrollLeft, scrollRight, scrollRef }) => {
  return (
    <div className="control-category">
      <label>Right Clothes:</label>
      <div className="control-options-container">
        <button className="arrow arrow-left" onClick={() => scrollLeft('rightClothes')}>{"<"}</button>
        <div className="control-options" ref={scrollRef}>
          <img
            src={emptyIcon}
            alt="None"
            onClick={() => setRightClothes(null)}
            className={rightClothes === null ? 'selected' : ''}
          />
          <img
            src={rightClothes1}
            alt="Right Clothes 1"
            onClick={() => setRightClothes(rightClothes1)}
            className={rightClothes === rightClothes1 ? 'selected' : ''}
          />
          <img
            src={rightClothes2}
            alt="Right Clothes 2"
            onClick={() => setRightClothes(rightClothes2)}
            className={rightClothes === rightClothes2 ? 'selected' : ''}
          />
          <img
            src={rightClothes3}
            alt="Right Clothes 3"
            onClick={() => setRightClothes(rightClothes3)}
            className={rightClothes === rightClothes3 ? 'selected' : ''}
          />
          <img
            src={rightClothes4}
            alt="Right Clothes 4"
            onClick={() => setRightClothes(rightClothes4)}
            className={rightClothes === rightClothes4 ? 'selected' : ''}
          />
          <img
            src={rightClothes5}
            alt="Right Clothes 5"
            onClick={() => setRightClothes(rightClothes5)}
            className={rightClothes === rightClothes5 ? 'selected' : ''}
          />
          <img
            src={rightClothes6}
            alt="Right Clothes 6"
            onClick={() => setRightClothes(rightClothes6)}
            className={rightClothes === rightClothes6 ? 'selected' : ''}
          />
          <img
            src={rightClothes7}
            alt="Right Clothes 7"
            onClick={() => setRightClothes(rightClothes7)}
            className={rightClothes === rightClothes7 ? 'selected' : ''}
          />
          <img
            src={rightClothes8}
            alt="Right Clothes 8"
            onClick={() => setRightClothes(rightClothes8)}
            className={rightClothes === rightClothes8 ? 'selected' : ''}
          />
          <img
            src={rightClothes9}
            alt="Right Clothes 9"
            onClick={() => setRightClothes(rightClothes9)}
            className={rightClothes === rightClothes9 ? 'selected' : ''}
          />
          <img
            src={rightClothes10}
            alt="Right Clothes 10"
            onClick={() => setRightClothes(rightClothes10)}
            className={rightClothes === rightClothes10 ? 'selected' : ''}
          />
          <img
            src={rightClothes11}
            alt="Right Clothes 11"
            onClick={() => setRightClothes(rightClothes11)}
            className={rightClothes === rightClothes11 ? 'selected' : ''}
          />
          <img
            src={rightClothes12}
            alt="Right Clothes 12"
            onClick={() => setRightClothes(rightClothes12)}
            className={rightClothes === rightClothes12 ? 'selected' : ''}
          />
          <img
            src={rightClothes13}
            alt="Right Clothes 13"
            onClick={() => setRightClothes(rightClothes13)}
            className={rightClothes === rightClothes13 ? 'selected' : ''}
          />
          <img
            src={rightClothes14}
            alt="Right Clothes 14"
            onClick={() => setRightClothes(rightClothes14)}
            className={rightClothes === rightClothes14 ? 'selected' : ''}
          />
          <img
            src={rightClothes15}
            alt="Right Clothes 15"
            onClick={() => setRightClothes(rightClothes15)}
            className={rightClothes === rightClothes15 ? 'selected' : ''}
          />
          {/* Add other rightClothes images similarly */}
        </div>
        <button className="arrow arrow-right" onClick={() => scrollRight('rightClothes')}>{">"}</button>
      </div>
    </div>
  );
};

export default RightClothes;


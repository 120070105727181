import React from 'react';
import emptyIcon from '../assets/pfpGenerator/empty_icon.png';
import background1 from '../assets/pfpGenerator/backgrounds/background_1.png';
import background2 from '../assets/pfpGenerator/backgrounds/background_2.png';
import background3 from '../assets/pfpGenerator/backgrounds/background_3.png';

const Background = ({ background, customBackground, setBackground, clearBackground, scrollLeft, scrollRight, scrollRef, handleBackgroundUpload }) => {
  return (
    <div className="control-category">
      <label>Background:</label>
      <div className="control-options-container">
        <button className="arrow arrow-left" onClick={() => scrollLeft('background')}>{"<"}</button>
        <div className="control-options" ref={scrollRef}>
          <img
            src={emptyIcon}
            alt="None"
            onClick={clearBackground}
            className={background === null && customBackground === null ? 'selected' : ''}
          />
          <img
            src={background1}
            alt="Background 1"
            onClick={() => setBackground(background1)}
            className={background === background1 ? 'selected' : ''}
          />
          <img
            src={background2}
            alt="Background 1"
            onClick={() => setBackground(background2)}
            className={background === background1 ? 'selected' : ''}
          />
          <img
            src={background3}
            alt="Background 1"
            onClick={() => setBackground(background3)}
            className={background === background1 ? 'selected' : ''}
          />
          {/* Add more background options as needed */}
        </div>
        <button className="arrow arrow-right" onClick={() => scrollRight('background')}>{">"}</button>
      </div>
      <label htmlFor="background-upload" className="upload-label">Upload custom PNG background</label>
      <input type="file" accept="image/*" id="background-upload" style={{ display: 'none' }} onChange={handleBackgroundUpload} />
    </div>
  );
};

export default Background;
